'use strict'
import type { IDataMain, IPageData } from '../../../types/page'
import Head from 'next/head'
import config from '../../../config'

const itemsBlocksTypes = [
  'article-list',
  'carousel-highlights',
  'category-header',
  'program-list',
  'program-video-list',
  'topic-header',
  'video-list',
] as const

const adapters = {
  default: (element: { link: string }): { url: string } => ({
    url: element.link,
  }),
}

export function generateItemList(modules: IPageData['data']): { position: number; url: string }[] {
  const mainSection = modules?.find((elem) => elem.key === 'main') as IDataMain
  const bodySection = mainSection?.data?.find((elem) => elem.key === 'body')
  const bodyHeaderSection = mainSection?.data?.find((elem) => elem.key === 'body-header')

  const blockList = [...(bodyHeaderSection?.data || []), ...(bodySection?.data || [])]

  const itemList = blockList
    .filter((block) => {
      const key = block.key as typeof itemsBlocksTypes[number]
      return itemsBlocksTypes.includes(key)
    })
    .flatMap((block) => {
      switch (block.key) {
        case 'article-list':
        case 'carousel-highlights':
        case 'category-header':
        case 'program-list':
        case 'program-video-list':
        case 'topic-header':
        case 'video-list':
          return block.data.elementList.map(adapters.default)
        default:
      }
    })

  const itemLists = itemList.map(({ url }, index) => ({
    position: index + 1,
    url,
  }))

  return itemLists
}

export function SEOItemLists({ modules }: { modules: IPageData['data'] }): JSX.Element {
  const list = generateItemList(modules)

  if (!list.length) return null

  const items = [
    {
      '@context': 'http://schema.org',
      '@type': 'ItemList',
      itemListElement:
        list?.map(({ position, url }) => ({
          '@type': 'ListItem',
          position,
          url: `${config.domain}${url}`,
        })) || [],
    },
  ]

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(items, null, config.environment === 'production' ? null : 4),
        }}
      />
    </Head>
  )
}
